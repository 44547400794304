import React from "react";

import Layout from "../components/Layout";

import { Helmet } from "react-helmet";
import { withPrefix, Link } from "gatsby";

const JobsPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <html lang="de" />
        <title>Jobs - Dr. Faltermeier</title>
        <meta name="description" content="Jobs - Dr. Faltermeier" />

        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content="Jobs - Dr. Faltermeier" />
        <meta property="og:url" content="/" />
        <meta property="og:description" content="Jobs - Dr. Faltermeier" />
        <meta
          property="og:image"
          content={`${withPrefix("/")}img/Dr-Faltermeier.jpeg`}
        />
      </Helmet>
      <Layout>
        <div class="max-w-1000p mx-auto px-5 mini:px-25p">
          <div className="markdownJobs mb-40p sm:mb-80p">
            <h1 className="text-primary text-center">Wir suchen dich!</h1>
            <h2 className="text-secondary text-center">
              Wir suchen ZMF und/oder ZMP (m/w/d) zur Unterstützung unseres
              Teams.
            </h2>
            <p>
              Aufgrund der stetig wachsenden Nachfrage nach biologischer
              Zahnmedizin erweitern wir unser Team.
              <br />
              Jedes Arbeitszeitmodell ist möglich.
            </p>
            <h2 className="text-secondary">Wir bieten:</h2>
            <ul>
              <li>Moderne Praxisaustattung</li>
              <li>Leistungsgerechte Entlohnung</li>
              <li>Flexible Urlaubszeiten</li>
              <li>4-Tage-Woche (Freitag frei)</li>
              <li>Fortbildungsmöglichkeiten</li>
              <li>Fahrtkostenzuschuss</li>
            </ul>
            <h2 className="text-secondary">Wir erwarten:</h2>
            <ul>
              <li>Freude am Beruf</li>
              <li>Selbständiges und gewissenhaftes Arbeiten</li>
              <li>Zuverlässigkeit</li>
              <li>PC Grundkenntnisse</li>
            </ul>
            <p>
              Als ZMF ist ihr Hauptarbeitsbereich die Behandlungsassistenz und
              Betreuung unserer Patienten.
              <br />
              Als ZMP unterstützen Sie unseren Prophylaxe-Bereich und betreuen
              Ihren eigenen Patientenstamm.
            </p>
            <p>
              Sie haben Lust auf eine berufliche Veränderung und suchen eine
              neue Herausforderung? <br />
              Dann bewerben Sie sich jetzt schriftlich per Post oder an{" "}
              <a href="mailto:praxis@drfaltermeier.de">
                praxis@drfaltermeier.de
              </a>
            </p>
            <p>Jede Bewerbung wird vertraulich behandelt.</p>
          </div>
          <div class="flex flex-col space-y-5 sm:space-y-0 sm:flex-row justify-evenly items-center max-w-5xl mx-auto sm:space-x-5">
            <Link
              data-sal="zoom-in"
              data-sal-duration="500"
              data-sal-easing="ease"
              class="text-secondary font-medium text-20p sm:text-25p text-center"
              to="/"
            >
              Zurück zur Startseite
            </Link>
            <a
              data-sal="zoom-in"
              data-sal-duration="500"
              data-sal-easing="ease"
              class="text-20p sm:text-25p text-white bg-cta hover:bg-cta2 p-20p sm:px-25p sm:py-3 text-center"
              href="mailto:praxis@drfaltermeier.de"
            >
              Jetzt bewerben
            </a>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default JobsPage;
